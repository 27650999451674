import * as React from "react"
import logoURI from "../images/logo.svg"

import styled from "styled-components"
import { Helmet } from "react-helmet"

// styles
const pageStyles: React.CSSProperties = {
}

const Main = styled.main`
  color: #232129;
  position: fixed;
  top: 0; left: 0;right: 0; bottom: 0;
  padding: 24px;
  font-family: -apple-system, Roboto, sans-serif, serif;
  background-color: rgba(235, 233, 229, 0.5);
  background-blend-mode: multiply;
  background-size: auto;

  @media (min-width: 600px) {
    padding: 48px;
  }

  @media (min-width: 800px) {
    padding: 96px;
  }

  ::before {
    content: "";
    display: block;
    background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(230,150,30,.10) 85%,rgba(230,150,30,.20) 100%);
    position: fixed;
    top: 0; left: 0;right: 0; bottom: 0;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
`

const ColoredH1 = styled.h1`
  color: #1a1a1a;
`

const WoxiA = styled.a`
  color: #1a1a1a;
  outline: #1a1a1a 2px solid;
  border-radius: 8px;
  padding: 8px 24px;
  text-decoration: none;
`

const MailA = styled.a`
  color: #1a1a1a;
  border-radius: 8px;
  padding: 8px;
`

const Logo = styled.img`
  max-width: 400px;
  max-height: 400px;
  width: 100%;
`

const SocialLink = styled.div`
  display: flex;
  margin: 8px;
  align-items: center;

  > *:first-child {
    margin-left: 0px;
  }

  > * {
    margin-left: 4px;
  }
`

const SocialLinks = styled.div`
  display: flex;
  margin: 8px;
  align-items: center;

  > *:first-child {
    margin-left: 0px;
  }

  > * {
    margin-left: 4px;
  }
`

const SocialIcon = styled.a`
  svg, img {
    transition: background-color 0.5s, fill 0.5s;
    border-radius: 100%;

    width: 40px;
    height: 40px;
    
    padding: 4px;
    background-color: transparent;
    fill: #8d1b1b;

    :hover {
      background-color: #8d1b1b;
      fill: white;
    }
  }
`

// markup
const IndexPage = () => {
  return (
    <Main style={pageStyles}>
      <Helmet title="CDSPA" defer={false}>
        <meta charSet="utf-8" />
        <title>CDSPA</title>
        <link rel="canonical" href="http://cdspa.cl" />
      </Helmet>
      <LogoContainer>
        <Logo draggable={false} src={logoURI} />
        <ColoredH1 style={{ marginTop: 8, marginBottom: 8 }}>Software a Medida</ColoredH1>
        <WoxiA style={{ marginTop: 8, marginBottom: 8 }} href="https://woxi.digital">Woxi</WoxiA>
        <MailA style={{ marginTop: 8, marginBottom: 8 }} href="mailto:info@cdspa.cl">info@cdspa.cl</MailA>
      </LogoContainer>
    </Main>
  )
}

export default IndexPage
